<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estrategias</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(actualizarFodaEstrategias)">
                                    <b-row>
                                        <b-col md="3">
                                            <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosFodaEstrategias.fecha"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                    <div class="py-2 px-2">
                                        <b-row>
                                            <b-col md="3" class="text-center">
                                                <validation-provider name="fortalezas" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Fortalezas:" class="mb-2">
                                                        <b-input-group v-for="(input,k) in datosFodaEstrategias.fortalezas" :key="k" class="mb-1">
                                                            <b-form-textarea rows="4" max-rows="6" size="md" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3" class="text-center">
                                                <validation-provider name="oportunidades" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Oportunidades:" class="mb-2">
                                                        <b-input-group v-for="(input,k) in datosFodaEstrategias.oportunidades" :key="k" class="mb-1">
                                                            <b-form-textarea rows="4" max-rows="6" size="md" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3" class="text-center">
                                                <validation-provider name="debilidades" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Debilidades:" class="mb-2">
                                                        <b-input-group v-for="(input,k) in datosFodaEstrategias.debilidades" :key="k" class="mb-1">
                                                            <b-form-textarea rows="4" max-rows="6" size="md" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3" class="text-center">
                                                <validation-provider name="amenazas" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Amenazas:" class="mb-2">
                                                        <b-input-group v-for="(input,k) in datosFodaEstrategias.amenazas" :key="k" class="mb-1">
                                                            <b-form-textarea rows="4" max-rows="6" size="md" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                        <b-row>
                                            <b-col md="12" class="text-right">
                                                <CButton @click="modalAgregarEstrategia = true" size="sm" color="dark">
                                                    <i class="fas fa-plus fa-sm"></i> Agregar estrategia FODA
                                                </CButton>
                                            </b-col>
                                        </b-row>
                                    </div>

                                    <b-col md="12" class="mt-2">
                                        <b-table :items="datosFodaEstrategias.listaEstrategias.filter(x => x.estado==2)" :fields="campoEstrategias" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                            <template #table-colgroup="campoEstrategias">
                                                <col v-for="field in campoEstrategias.fields" :key="field.key" :style="{ width: field.key === 'estrategia' ? '50%' : 'acciones' ? '10%' : 'descripcion' ? '30%' : '10%' }">
                                            </template>
                                            <template #cell(descripcion)="param">
                                                <div class="text-left">
                                                    {{param.item.descripcion}}
                                                </div>
                                            </template>
                                            <template #cell(estrategia)="param">
                                                <div class="text-left">
                                                    {{param.item.estrategia}}
                                                </div>
                                            </template>
                                            <template #cell(acciones)="param">
                                                <b-button v-if="param.item.idFodaEstrategia!=0" class="mr-2" @click="planificarEstrategia(param)" size="sm" variant="dark" v-c-tooltip="'Planificación'">
                                                    <i class="fas fa-search my-0 mx-0"></i>
                                                </b-button>
                                                <b-button @click="quitarEstrategia(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                    <b-col md="12">
                                        <b-row class="text-center">
                                            <b-col md="12">
                                                <b-button :to="{name: 'FODA'}" class="mr-2" variant="dark">
                                                    <i class="fas fa-arrow-left"></i> Volver
                                                </b-button>
                                                <b-button v-if="checkPermissions('003-RIE-FOD','u') == 1" :disabled="disabled" variant="success" type="submit">
                                                    <i class="fas fa-save"></i> Guardar
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                </b-form>
                            </validation-observer>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalAgregarEstrategia">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Agregar estrategia </span>
            </h6>
            <CButtonClose @click="modalAgregarEstrategia = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(agregarEstrategiaLista)">
                <b-row>
                </b-row>
                <div class="py-2 px-2">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="tipo de estrategia" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Tipo de estrategia:" class="mb-2">
                                    <b-input-group class="mb-1">
                                        <b-form-select v-model="datosEstrategia.tipoEstrategia" :options="comboTipoEstrategia" :state="getValidationState(validationContext)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </template>
                                        </b-form-select>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="descripción" v-slot="validationContext">
                                <b-form-group label="Descripción:" class="mb-2">
                                    <b-form-textarea rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" v-model="datosEstrategia.descripcion"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="estrategia" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Estrategia:" class="mb-2">
                                    <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la estrategia" v-model="datosEstrategia.estrategia"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </div>

                <b-col class="my-2 text-right" md="12">
                    <b-button size="md" variant="success" class=" mr-2" type="submit">
                        Agregar
                    </b-button>
                    <b-button size="md" variant="danger" @click="modalAgregarEstrategia = false">
                        Cancelar
                    </b-button>
                </b-col>

            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalPlanificacionEstrategia">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Planificación de la estrateggia </span>
            </h6>
            <CButtonClose @click="modalPlanificacionEstrategia = false" class="text-white" />
        </template>

        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPlanificacion)">
                <b-row>
                </b-row>
                <div class="py-2 px-2">
                    <b-row>
                        <b-col md="12" class="mt-2">
                            <CButton size="sm" class="float-right" color="dark" @click="agregarPlanificacion()">
                                <i class="fas fa-plus fa-sm"></i> Agregar
                            </CButton>
                        </b-col>
                        <b-col md="12" class="mt-2">
                            <b-table :items="listaPlanificaciones.filter(x => x.estado==2)" :fields="cabeceraPlanificacion" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                <template #table-colgroup="cabeceraPlanificacion">
                                    <col v-for="field in cabeceraPlanificacion.fields" :key="field.key" :style="{ width: field.key === 'acciones' ? '20%' : field.key === 'responsable' ? '25%' : field.key === 'plazo' ? '20%' : field.key === 'idProceso' ? '20%' : '10%' }">
                                </template>
                                <template v-slot:cell(responsable)="rowPlani">
                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model="rowPlani.item.idPuestoTrabajo" :options="comboCargoResponsable">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <v-select :reduce="comboResponsable =>comboResponsable.idEmpleado" class="mt-2" label="nombres" placeholder="Seleccione una opción" v-model="rowPlani.item.idEmpleado" :options="comboResponsable">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                </template>
                                <template v-slot:cell(acciones)="rowPlani">
                                    <b-form-textarea v-model="rowPlani.item.acciones" rows="3" max-rows="6" placeholder="Ingrese acciones"></b-form-textarea>
                                </template>
                                <template v-slot:cell(idProceso)="rowPlani">
                                    <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" v-model="rowPlani.item.idProceso" :options="comboProcesos">
                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                    </v-select>
                                </template>
                                <template v-slot:cell(plazo)="rowPlani">
                                    <b-form-select v-model="rowPlani.item.plazo" :options="comboPlazo" value-field="idPlazo" text-field="descripcion">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </template>
                                <template v-slot:cell(ejecutada)="rowPlani">
                                    <b-form-checkbox plain :id="'checkbox-'+rowPlani.index" v-model="rowPlani.item.ejecutada" :name="'checkbox-'+rowPlani.index" value="2" unchecked-value="1"></b-form-checkbox>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-show="listaPlanificaciones.length>1" @click="quitarPlanificacion(param)" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </div>

                <b-col class="my-2 text-right" md="12">
                    <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                        Guardar
                    </b-button>
                    <b-button size="md" variant="danger" @click="modalPlanificacionEstrategia = false">
                        Cancelar
                    </b-button>
                </b-col>

            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarEstrategia: false,
            modalPlanificacionEstrategia: false,
            comboTipoEstrategia: [
                {
                    value: 'FO',
                    text: 'Fortalezas Oportunidades (FO)'
                },
                {
                    value: 'FA',
                    text: 'Fortalizas Amenazas (FA)'
                },
                {
                    value: 'DO',
                    text: 'Debilidades Oportunidades (DO)'
                },
                {
                    value: 'DA',
                    text: 'Debilidades Amenazas (DA)'
                },
            ],
            campoEstrategias: [{
                    key: "tipoEstrategia",
                    label: "Tipo de estrategia",
                    class: "text-center",
                },
                {
                    key: "descripcion",
                    label: "Descripción",
                    class: "text-center",
                },
                {
                    key: "estrategia",
                    label: "Estrategia",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            cabeceraPlanificacion: [{
                key: 'idProceso',
                label: 'Proceso',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: 'Acciones',
                class: 'text-center'
            }, {
                key: 'responsable',
                label: 'Responsable',
                class: 'text-center'
            }, {
                key: 'plazo',
                label: 'Plazo',
                class: 'text-center'
            }, {
                key: 'ejecutada',
                label: 'Ejecutada',
                class: 'text-center'
            }, {
                key: 'opciones',
                label: '',
                class: 'text-center'
            }],
            comboPlazo: [{
                idPlazo: '1',
                descripcion: '6 Meses'
            }, {
                idPlazo: '2',
                descripcion: '12 Meses'
            }, {
                idPlazo: '3',
                descripcion: '18 Meses'
            }, {
                idPlazo: '4',
                descripcion: 'Otros'
            }],

            datosFodaEstrategias: {
                idFoda: '',
                fecha: '',
                fortalezas: [],
                oportunidades: [],
                debilidades: [],
                amenazas: [],
                listaEstrategias: [],
            },
            datosEstrategia: {
                idFodaEstrategia: '',
                tipoEstrategia: null,
                estrategia: '',
                descripcion: ''
            },

            condicionSelect: '',
            disabled: false,
            datosSession: {
                idCliente: ''
            },
            listaPlanificaciones: [],
            comboCargoResponsable: [],
            comboResponsable: [],
            comboProcesos: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurModificarProceso() {
            this.computedForm2.refs.proceso_.validate();
        },
        listarFodaDetalle() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-foda-detalle", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idFoda: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    me.datosFodaEstrategias.idFoda = response.data[0].idFoda;
                    me.datosFodaEstrategias.fecha = response.data[0].fecha;

                    for (const i in response.data) {
                        if (response.data[i].identificador == 'F') {
                            me.datosFodaEstrategias.fortalezas.push({
                                idFodaDetalle: response.data[i].idFodaDetalle,
                                identificador: response.data[i].identificador,
                                descripcion: response.data[i].descripcion
                            })
                        }
                        if (response.data[i].identificador == 'O') {
                            me.datosFodaEstrategias.oportunidades.push({
                                idFodaDetalle: response.data[i].idFodaDetalle,
                                identificador: response.data[i].identificador,
                                descripcion: response.data[i].descripcion
                            })
                        }
                        if (response.data[i].identificador == 'D') {
                            me.datosFodaEstrategias.debilidades.push({
                                idFodaDetalle: response.data[i].idFodaDetalle,
                                identificador: response.data[i].identificador,
                                descripcion: response.data[i].descripcion
                            })
                        }
                        if (response.data[i].identificador == 'A') {
                            me.datosFodaEstrategias.amenazas.push({
                                idFodaDetalle: response.data[i].idFodaDetalle,
                                identificador: response.data[i].identificador,
                                descripcion: response.data[i].descripcion
                            })
                        }
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarFodaEstrategias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-foda-estrategias", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idFoda: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    me.datosFodaEstrategias.listaEstrategias = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarFodaEstrategiasPlanificaciones(idFodaEstrategia) {
            let me = this;
            
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-foda-estrategias-planificaciones", {
                    params: {
                        idFodaEstrategia: idFodaEstrategia,
                    }
                })
                .then(function (response) {
                    if (response.data.length > 0) {
                        for (const i in response.data) {
                            me.listaPlanificaciones.push({
                                idFodaEstrategiaPlanificacion: response.data[i].idFodaEstrategiaPlanificacion,
                                idFodaEstrategia: response.data[i].idFodaEstrategia,
                                idProceso: response.data[i].idProceso,
                                acciones: response.data[i].acciones,
                                idPuestoTrabajo: response.data[i].idCargoResponsable,
                                idEmpleado: response.data[i].idResponsable,
                                plazo: response.data[i].plazo,
                                ejecutada: response.data[i].ejecutada,
                                estado: response.data[i].estado,
                            })
                        }
                    } else {
                        me.listaPlanificaciones.push({
                            idFodaEstrategiaPlanificacion: 0,
                            idFodaEstrategia: idFodaEstrategia,
                            idProceso: '',
                            acciones: '',
                            idPuestoTrabajo: '',
                            idEmpleado: '',
                            plazo: null,
                            ejecutada: '1',
                            estado: 2,
                        })
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarFodaEstrategias() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/modificar-foda-estrategias", {
                        datosFoda: me.datosFodaEstrategias,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.disabled = false;
                    me.$router.push({
                        name: 'FODA'
                    })
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarPlanificacion() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-foda-estrategia-planificacion", {
                        listaPlanificaciones: me.listaPlanificaciones,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.disabled = false;
                    me.modalPlanificacionEstrategia = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        planificarEstrategia(param) {
            let me = this;
            me.listaPlanificaciones.length = 0;
            me.modalPlanificacionEstrategia = true;
            me.listarFodaEstrategiasPlanificaciones(param.item.idFodaEstrategia);
        },
        agregarPlanificacion() {
            let me = this;
            me.listaPlanificaciones.push({
                idFodaEstrategiaPlanificacion: 0,
                idFodaEstrategia: me.listaPlanificaciones[0].idFodaEstrategia,
                idProceso: '',
                acciones: '',
                idPuestoTrabajo: '',
                idEmpleado: '',
                plazo: null,
                ejecutada: '1',
                estado: 2,
            })
        },
        quitarPlanificacion(param) {
            let me = this;
            let listaPlani = me.listaPlanificaciones.filter(x => x.estado == 2);
            if (listaPlani[param.index].idFodaEstrategiaPlanificacion) {
                listaPlani[param.index].estado = 1;
            } else if (listaPlani[param.index].idFodaEstrategiaPlanificacion == 0) {
                listaPlani[param.index].estado = 0;
                for (let e in me.listaPlanificaciones) {
                    if (me.listaPlanificaciones[e].estado == 0) {
                        me.listaPlanificaciones.splice(e, 1);
                    }
                }
            }
        },
        quitarEstrategia(param) {
            let me = this;
            let listaEst = me.datosFodaEstrategias.listaEstrategias.filter(x => x.estado == 2);
            if (listaEst[param.index].idFodaEstrategia) {
                listaEst[param.index].estado = 1;
            } else if (listaEst[param.index].idFodaEstrategia == 0) {
                listaEst[param.index].estado = 0;
                for (let e in me.datosFodaEstrategias.listaEstrategias) {
                    if (me.datosFodaEstrategias.listaEstrategias[e].estado == 0) {
                        me.datosFodaEstrategias.listaEstrategias.splice(e, 1);
                    }
                }
            }
        },
        agregarEstrategiaLista() {
            let me = this;
            me.datosFodaEstrategias.listaEstrategias.push({
                idFodaEstrategia: 0,
                tipoEstrategia: me.datosEstrategia.tipoEstrategia,
                descripcion: me.datosEstrategia.descripcion,
                estrategia: me.datosEstrategia.estrategia,
                estado: 2
            });
            me.modalAgregarEstrategia = false;
        },
        resetModalAgregarEstrategia() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosEstrategia.tipoEstrategia = null,
                this.datosEstrategia.descripcion = '',
                this.datosEstrategia.estrategia = ''
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalAgregarEstrategia: function (val) {
            if (val == false) {
                this.resetModalAgregarEstrategia();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.listarEmpleados();
            this.listarProcesos();
            if (this.$route.params.id != null) {
                this.listarFodaDetalle();
                this.listarFodaEstrategias();
            }
        }
    }

}
</script>
